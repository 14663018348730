import { useState } from "react";
import LinkedInButton from "./LinkedInButton";

export default function ShowHideContact() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  return (
    <div>
      <button
        className="relative z-10 inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-rcg-200 to-rcg-200 group-hover:from bg-rcg-200 group-hover:to-slate-500 hover:text-stone-900 dark:text-stone-900"
        onClick={handleClick}
      >
        <span className="spanButton">Contact</span>
      </button>
      {/* 👇️ show component on click */}
      {isShown && (
        <div className="animate-fade-down mt-6 mb-4 p-4 bg-white w-60 shadow-md">
          <h6>Evan Purvis</h6>
          <a
            href="mailto:evan@ringsconsulting.com"
            className="text-blue-950 underline"
          >
            Let's connect!
          </a>
          <div className="pt-2">
            <LinkedInButton />
          </div>
        </div>
      )}
    </div>
  );
}
