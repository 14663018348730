import { useState } from "react";
import TableauEmbed from "./TableauEmbed";
import TableauEmbedProjectA from "./TableauEmbedProjectA";

export default function ShowHidePortfolio() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  return (
    <div className="fade-in">
      {!isShown && (
        <span class="flex relative z-10 translate-x-[5.7rem] translate-y-2 h-3 w-3">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
        </span>
      )}
      <button
        className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-rcg-100 to-rcg-100 group-hover:from bg-rcg-100 group-hover:to-slate-500 hover:text-stone-900 dark:text-stone-900"
        onClick={handleClick}
      >
        <span className="spanButton">Portfolio</span>
      </button>
      {/* 👇️ show component on click */}
      {isShown && (
        <div className="pt-4 animate-fade-up z-10">
          <TableauEmbedProjectA />
        </div>
      )}
      {/* 👇️ show component on click */}
      {isShown && (
        <div className="pt-4 animate-fade-up z-10">
          <TableauEmbed />
        </div>
      )}
    </div>
  );
}
