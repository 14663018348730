import { useState, useEffect } from "react";
import resume from "../resume.pdf";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js";

export default function ShowHideResume() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  const [dynamicWidth, setDynamicWidth] = useState(612); // Initialize with an initial width

  useEffect(() => {
    const updateWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 612) {
        setDynamicWidth(screenWidth);
      } else {
        setDynamicWidth(612);
      }
    };
    updateWidth();

    // window.addEventListener("resize", updateWidth);

    // return () => {
    //   window.removeEventListener("resize", updateWidth);
    // };
  }, []);

  return (
    <div>
      <button
        className="relative z-10 inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-rcg-300 to-rcg-300 group-hover:from bg-rcg-300 group-hover:to-slate-500 hover:text-stone-900 dark:text-stone-900
        focus:ring-4 focus:outline-none focus:ring-rcg-300 dark:focus:ring-white"
        onClick={handleClick}
      >
        <span className="spanButton">Resume</span>
      </button>
      {/* 👇️ show component on click */}
      {isShown && (
        <div className="animate-fade-down pt-4 ">
          <div className="-translate-y-10 -z-50 -mb-60">
            <Document
              file={resume}
              onLoadError={console.error}
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Page
                pageIndex={0}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={dynamicWidth}
              />
            </Document>
          </div>
        </div>
      )}
    </div>
  );
}
