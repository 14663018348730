import logo from "./rcgLogo.png";
import ShowHidePortfolio from "./components/ShowHidePortfolio";
import ShowHideContact from "./components/ShowHideContact";
import ShowHideResume from "./components/ShowHideResume";
import SVGBackground from "./components/SVGBackground";

function App() {
  return (
    <div className="container text-center my-auto mx-auto px-4 flex gap 2 flex-col">
      <div className="flex banner-resize-ls lg:px-48 slide-left">
        <div className="w-48">
          <img className="animate-spin-slow" src={logo} alt="logo" />
        </div>
        <div className="text-left flex items-center">
          <p className="pt-8 font-medium text-3xl">Moving forward with you.</p>
        </div>
      </div>
      <div className="w-auto h-auto px-10">
        <div className="navAlign">
          <ShowHidePortfolio />
        </div>
        <div className="navAlign">
          <div className="flex align-middle">
            <ShowHideContact />
          </div>
        </div>
        <div className="navAlign">
          <div className="flex align-middle">
            <ShowHideResume />
          </div>
        </div>
      </div>
      {/* <SVGBackground /> */}
    </div>
  );
}

export default App;
