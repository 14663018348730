import React from "react";
import { useState, useEffect } from "react";

function TableauEmbedProjectA() {
  const [dynamicWidth, setDynamicWidth] = useState(932); // Initialize with an initial width
  const [deviceType, setDeviceType] = useState("desktop");
  const [tailwindDynamicWidth, setTailwindDynamicWidth] = useState(612);
  useEffect(() => {
    const updateWidth = () => {
      const screenWidth = window.outerWidth;
      if (screenWidth <= 932) {
        setDynamicWidth(screenWidth);
        setDeviceType("phone");
        setTailwindDynamicWidth("w-screen");
      } else {
        setDynamicWidth(932);
        setDeviceType("desktop");
        setTailwindDynamicWidth("w-auto");
      }
    };
    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    <div className={tailwindDynamicWidth}>
      <tableau-viz
        id="tableauViz"
        src="https://public.tableau.com/views/ASCDashboard_17043074714550/ASCMotors?:language=en-US&:display_count=n&:origin=viz_share_link"
        toolbar="hidden"
        device={deviceType}
        width={"auto"}
      ></tableau-viz>
    </div>
  );
}

export default TableauEmbedProjectA;
